module.exports = [{
      plugin: require('/var/www/vincentgache.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-1794253-6"},
    },{
      plugin: require('/var/www/vincentgache.com/node_modules/gatsby-plugin-manifest/gatsby-browser'),
      options: {"plugins":[],"name":"Vincent GACHE","short_name":"Vincent GACHE","description":"Vincent GACHE's Official website. About, CV and blog. Opinions are my own.","start_url":"/","icon":"src/pages/icon.png","background_color":"#FFFFFF","theme_color":"#222222","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b68f54af8bdd72f2724d730be76f997d"},
    },{
      plugin: require('/var/www/vincentgache.com/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    }]
